import { Intent, Position, Toaster } from '@blueprintjs/core'

const Notification = Toaster.create({
  className: 'recipe-toaster',
  position: Position.TOP,
});

Notification.warning = (message, { icon, action } = {}) => {
  Notification.show({
    intent: Intent.WARNING,
    icon: icon || 'warning-sign',
    action: action || null,
    message: message,
  })
};

Notification.failed = (message, { icon, action } = {}) => {
  Notification.show({
    intent: Intent.DANGER,
    icon: icon || 'issue',
    action: action || null,
    message: message,
  })
};

Notification.success = (message, { icon, action } = {}) => {
  Notification.show({
    intent: Intent.SUCCESS,
    icon: icon || 'tick',
    action: action || null,
    message: message,
  })
};

Notification.info = (message, { icon, action } = {}) => {
  Notification.show({
    intent: Intent.PRIMARY,
    icon: icon || 'info-sign',
    action: action || null,
    message: message,
  })
};

export default Notification;
