export default {
  SIGN_IN: '/auth',

  HOME: '/',

  NOTICE_LIST: '/notices',
  NOTICE_TRASH: '/notices?state=trash',
  NOTICE_ITEM: '/notices/:id',
  NOTICE_EDIT: '/notices/:id/edit',
  NOTICE_CREATE: '/notices/create',

  CLIENT_LIST: '/clients',
  CLIENT_CREATE: '/clients/create',
};
