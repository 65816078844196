import React, { useState, useEffect, lazy, Suspense } from 'react';
import firebase from 'firebase/app';

import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import axiosInstance from '@api/index';

import routes from '@configs/routes';
import { IS_LOGIN } from '@configs/storageKeys';

const Home = lazy(() => import('@components/pages/Home'));
const Auth = lazy(() => import('@components/pages/Auth'));
const NotFound = lazy(() => import('@components/pages/NotFound'));

const Notices = lazy(() => import('@components/pages/Notices'));
const CreateNotice = lazy(() => import('@components/pages/Notices/create'));
const EditNotice = lazy(() => import('@components/pages/Notices/edit'));

const Clients = lazy(() => import('@components/pages/Clients'));
const CreateClient = lazy(() => import('@components/pages/Clients/create'));

const Loading = lazy(() => import('@components/pages/Loading'));

const PrivateRoute = (props) => {
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    const unsubscribe = firebase.auth()
      .onAuthStateChanged(async user => {
        if (user) {
          const token = await user.getIdToken();
          axiosInstance.setToken(token);
        }
        setIsLogin(!!user);
      });

    return () => {
      unsubscribe();
    };
  }, []);

  return isLogin
    ? <Route {...props} />
    : localStorage.getItem(IS_LOGIN)
      ? <Loading/>
      : <Redirect to={routes.SIGN_IN}/>;
};

export default () => {
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        localStorage.setItem(IS_LOGIN, Boolean(user).toString());
      } else {
        localStorage.removeItem(IS_LOGIN);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <PrivateRoute exact path={routes.HOME} component={Home} />

          {/* 리소스 페이지들 */}
          <PrivateRoute exact path={routes.NOTICE_LIST} component={Notices} />
          <PrivateRoute path={routes.NOTICE_CREATE} component={CreateNotice} />
          <PrivateRoute path={routes.NOTICE_EDIT} component={EditNotice} />

          <PrivateRoute exact path={routes.CLIENT_LIST} component={Clients} />
          <PrivateRoute path={routes.CLIENT_CREATE} component={CreateClient} />

          {/* 기능 페이지들 */}
          <Route path={routes.SIGN_IN} component={Auth} />
          <Route exact path="*" component={NotFound} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};
