export default Object.freeze({
  API_URL: process.env.REACT_APP_ADMIN_API_URL,

  FIREBASE: {
    API: process.env.REACT_APP_FIREBASE_API_KEY,
    AUTH: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    DATABASE: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    STORAGE: process.env.REACT_APP_FIREBAES_STORAGE_BUCKET,
    MESSAGING: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    APP_ID: process.env.REACT_APP_FIREBASE_APP_ID,
    MEASUREMENT_ID: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  }
});
