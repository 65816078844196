import React from 'react';
import { ThemeProvider } from 'styled-components';
import ReactDOM from 'react-dom';
import { FocusStyleManager } from '@blueprintjs/core';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';

import 'normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import 'react-quill/dist/quill.snow.css';

import App from '@components/App';
import env from '@configs/env';

FocusStyleManager.onlyShowFocusOnTabs();

// eslint-disable-next-line import/no-webpack-loader-syntax
const theme = require('sass-extract-loader?{"plugins": ["sass-extract-js"]}!../node_modules/@blueprintjs/core/lib/scss/variables.scss');

firebase.initializeApp({
  apiKey: env.FIREBASE.API,
  authDomain: env.FIREBASE.AUTH,
  databaseURL: env.FIREBASE.DATABASE,
  projectId: env.FIREBASE.PROJECT_ID,
  storageBucket: env.FIREBASE.STORAGE,
  messagingSenderId: env.FIREBASE.MESSAGING,
  appId: env.FIREBASE.APP_ID,
  measurementId: env.MEASUREMENT_ID,
});

const Container = () => <>
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>
</>

ReactDOM.render(<Container />, document.getElementById('root'));
