import axios from 'axios';
import firebase from 'firebase/app';
import Notification from '@ui/notification';
import env from '@configs/env';

const refreshToken = async () => {
  await firebase.auth().updateCurrentUser();
  return firebase.auth().currentUser.getIdTokenResult();
};

const instance = axios.create({
  baseURL: env.API_URL,
  mode: 'no-cors',
  headers: {
    'access-control-allow-origin': '*',
    'content-type': 'application/json',
  },
});

instance.setToken = (token) => {
  instance.defaults.headers.common.Authorization = `Bearer ${token}`;
};

instance.interceptors.response.use(res => res, async (err) => {
  if (!err) return;
  if (err.response && err.response.status === 401) {
    instance.setToken(await refreshToken());
    Notification.info('원활한 사용을 위해 새로고침을 해주세요.');
  }
  await Promise.reject(err);
});

export default instance;
